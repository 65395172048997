import React, { Component } from 'react';
import './sampleItem.css';
import {NavLink} from 'react-router-dom';

class SampleItem extends Component {

  constructor(props){
    super(props);
    if (this.props.match.params.id === "1"){
      this.state={active:"side"}
    }else{
      this.state={active:"front"}
    }
  }


  render() {
    const id = this.props.match.params.id;
    const gender = this.props.match.params.gender;
    const serial = this.props.match.params.serial
    var title, info, info2, info3, info4;
    if (this.props.location.aboutProps) {
      title = this.props.location.aboutProps.title;
      info = this.props.location.aboutProps.info;
      info2 = this.props.location.aboutProps.info2;
      info3 = this.props.location.aboutProps.info3;
      info4 = this.props.location.aboutProps.info4;
    }
    return (
        <main className="samples-page">
          <div className='about-section-content'>
              <div className="catalog">
                <div className="image-toggle">
                  <NavLink to="/Samples">
                    <button className="back-button">Back</button>
                  </NavLink>
                  <h1 className="sample-item-title">{serial}</h1>
                    <div className="filling-subtitle">
                      {
                        title && <h3 className="sample-item-title">{title}</h3>
                      }
                        {
                          info && <h3 className="sample-item-subtitle">{info}</h3>
                        }
                        {
                          info2 && <h3 className="sample-item-subtitle">{info2}</h3>
                        }
                        {
                          info3 && <h3 className="sample-item-subtitle">{info3}</h3>
                        }
                        {
                          info4 && <h3 className="sample-item-subtitle">{info4}</h3>
                        }
                      </div>
                  <div className="image-box">
                    <img className="toggle-image"
                       onClick={()=>this.setState({active:"front"})}
                       onMouseOver={()=>this.setState({active:"front"})}
                       onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/front.jpg'}/>
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"side"})}
                      onMouseOver={()=>this.setState({active:"side"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/side.jpg'}/>
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"back"})}
                      onMouseOver={()=>this.setState({active:"back"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/back.jpg'}/>
                  </div>
                  <div className="image-box">
                    <img className="toggle-image"
                       onClick={()=>this.setState({active:"front_b"})}
                       onMouseOver={()=>this.setState({active:"front_b"})}
                       onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/front_b.jpg'}/>
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"side_b"})}
                      onMouseOver={()=>this.setState({active:"side_b"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/side_b.jpg'}/>
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"back_b"})}
                      onMouseOver={()=>this.setState({active:"back_b"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/back_b.jpg'}/>
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"back_with_hood"})}
                      onMouseOver={()=>this.setState({active:"back_with_hood"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/back_with_hood.jpg'}/>
                  </div>
                  <div className="image-box">
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"model_front"})}
                      onMouseOver={()=>this.setState({active:"model_front"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/model_front.jpg'}/>
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"model_back"})}
                      onMouseOver={()=>this.setState({active:"model_back"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/model_back.jpg'}/>
                    <img className="toggle-image"
                      onClick={()=>this.setState({active:"model_side"})}
                      onMouseOver={()=>this.setState({active:"model_side"})}
                      onError={i => i.target.style.display='none'} alt="jacket"
                      src={'/images/jackets/'+gender+'/'+id+'/model_side.jpg'}/>
                  </div>
                <div className="image-box">
                      <img className="toggle-image"
                        onClick={()=>this.setState({active:"model_front_b"})}
                        onMouseOver={()=>this.setState({active:"model_front_b"})}
                        onError={i => i.target.style.display='none'} alt="jacket"
                        src={'/images/jackets/'+gender+'/'+id+'/model_front_b.jpg'}/>
                      <img className="toggle-image"
                        onClick={()=>this.setState({active:"model_back_b"})}
                        onMouseOver={()=>this.setState({active:"model_back_b"})}
                        onError={i => i.target.style.display='none'} alt="jacket"
                        src={'/images/jackets/'+gender+'/'+id+'/model_back_b.jpg'}/>
                      <img className="toggle-image"
                        onClick={()=>this.setState({active:"model_side_b"})}
                        onMouseOver={()=>this.setState({active:"model_side_b"})}
                        onError={i => i.target.style.display='none'} alt="jacket"
                        src={'/images/jackets/'+gender+'/'+id+'/model_side_b.jpg'}/>
                  </div>

                </div>
                <img className="main-image" onError={i => i.target.style.display='none'} alt="jacket"
                  src={`/images/jackets/${gender}/${id}/${this.state.active}.jpg`}/>

              </div>

          </div>

        </main>
    );
  }
}

export default SampleItem;
