import React, { Component } from 'react';
import './requestPage.css';
import RequestMain from './requestMainComponent/requestMain';

class RequestPage extends Component {
  render() {
    return (
        <main className="request-page">
          <RequestMain/>
        </main>
    );
  }
}

export default RequestPage;
