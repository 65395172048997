import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import {addLocaleData} from 'react-intl';
// import locale_en from 'react-intl/locale-data/en';
// import locale_zh from 'react-intl/locale-data/zh';
// import messages_zh from './translations/zh.json';
// import messages_en from './translations/en.json';

// import { createStore, combineReducers } from 'redux';
// import { Provider } from 'react-redux';
// import { IntlProvider, intlReducer } from 'react-intl-redux';
//
// const reducer = combineReducers({
//   intl: intlReducer,
// })
//
// const messages = {
//   'zh': messages_zh,
//   'en': messages_en
// }
//
// const language = (navigator.languages && navigator.languages[0])
//                || navigator.language
//                || navigator.userLanguage
//                || 'en';
//
// const initialState = {
//   intl: {
//     defaultLocale: 'zh',
//     locale: 'en',
//     messages: {
//       'zh': messages_zh,
//       'en': messages_en
//     }
//   }
// }
//
// const store = createStore(reducer, initialState);
//
// addLocaleData([...locale_en, ...locale_zh]);

ReactDOM.render(
   <App />,
   document.getElementById('root')
 );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
