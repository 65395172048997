import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './map.css';


export class MapContainer extends Component {
  render() {
    const mapStyles = {
      width: '60%',
      height: '100%',
      margin: '0 auto 0 auto'
    };

    return (
      <div className='map-wrapper'>
        <Map google={this.props.google} zoom={15} style={mapStyles}
           initialCenter={{lat: 35.82584312,lng: 115.4943853,}}>
         <Marker />
       </Map>
      </div>

    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC2lcSUWcETQFPcf9HGLU78Rtbgh-nVG1U'
})(MapContainer);
