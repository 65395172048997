import React, { Component } from 'react';
import './samplePage.css';
import { FormattedMessage } from 'react-intl';
import SamplePageCatalog from './samplePageCatalogComponent/samplePageCatalog';

// const womens5 = [
//   ['images/jackets/women/50/front.jpg','Trench Coat With Detachable Hood','SS22-030',"50","Fabric : 100% Polyester"],
//   ['images/jackets/women/51/front.jpg','Over-sized Short Jacket','SS22-029',"51","Fabric : 100% Nylon Heavy Twill "],
//   ['images/jackets/women/52/front.jpg','Rain Coat','SS22-028',"52","Fabric : 100% Polyester Bonding "],
//   ['images/jackets/women/53/front.jpg','Light Weight Down Jacket','SS22-027',"53","Fabric : 100% Nylon","Lining : 100% Polyester","Filling : 90% Down 10% Feather / 40G"],
//   ['images/jackets/women/74/front.jpg','Long Coat','SS22-026',"74","Fabric : 100% Nylon","Lining : 100% Polyester"],
//   ['images/jackets/women/54/front.jpg','Wind Coat','SS22-025',"54","Fabric : 100% Polyester","Lining : 100% Polyester"],
//   ['images/jackets/women/55/front.jpg','Trench Coat','SS22-024',"55","Fabric : 62% Ceather 38% Nylon","Lining : Polyester"],
//   ['images/jackets/women/75/front.jpg','Sport Wind Break Jacket With Water Proof','SS22-023',"75","Fabric : 84% Nylon 16% Spandex Bonding "],
//   ['images/jackets/women/56/front.jpg','Lightweight Down Jack With Knitting Back Panel','SS22-022',"56","Fabric : 100% Nylon Rib ","Lining : 100% Nylon","Knitting : Linen Ceather ","Filling : 90% Down 10% Feather "],
//   ['images/jackets/women/57/front.jpg','Big Collar Windbreak Jacket With Hide Hood','SS22-020',"57","Fabric : 100% Nylon Rib ","Lining : 100% Polyester"],
//   ['images/jackets/women/58/front.jpg','Windbreak Jacket','SS22-019',"58","Fabric : 100% Nylon Rib Stop ","Lining : 100% Nylon"],
//   ['images/jackets/women/59/front.jpg','Short Jacket','SS22-018',"59","Fabric : 100% Nylon Rib Stop Ceather Touch","Lining : 100% Polyester"],
//   ['images/jackets/women/76/front.jpg','Satin Jacket','SS22-017',"76","Fabric : 100% Polyester","Lining : 100% Polyester"],
//   ['images/jackets/women/60/front.jpg','Trench Coat','SS22-016',"60","Fabric : 68% Ceather 32% Nylon","Lining : 100% Polyester"],
//   ['images/jackets/women/61/front.jpg','Lightweight Down Jacket With Rib Collar','SS22-015',"61","Fabric : 100% Nylon","Lining : 100% Polyester","Filling : 90% Down 10% Feather 90G"],
// ]
//
// const womens6 = [
//   ['images/jackets/women/62/front.jpg','Windbreak Jacket With Hoody','SS22-014',"62","Fabric : 100% Polyester ","Lining : 100% Polyester"],
//   ['images/jackets/women/63/front.jpg','Trench Coat with Hoody','SS22-012',"63","Fabric : 100% Polyester Sharp Memory ","Lining : 100% Polyester"],
//   ['images/jackets/women/64/front.jpg','Big Collar Oversized Jacket','SS22-011',"64","Fabric : 100% Nylon","Lining : 100% Polyester"],
//   ['images/jackets/women/65/front.jpg','Windbreak Jacket With Hide Hood','SS22-010',"65","Fabric : 100% Nylon Mini Rib Stop",
//   "Lining : 100% Polyester"],
//   ['images/jackets/women/66/front.jpg','Trench Coat With Rib Collar And Cuff','SS22-009',"66","Fabric : 51% Ceather 44% Nylon 5% Spandex","Lining : 100% Polyester"],
//   ['images/jackets/women/67/front.jpg','Trench Coat','SS22-008',"67","Fabric : 38% Ceather 38% Polyester 24% Polyester","Lining : 100% Polyester"],
//   ['images/jackets/women/68/front.jpg','Trench Parka','SS22-007',"68","Fabric : 100% Polyester","Lining : 100% Polyester"],
//   ['images/jackets/women/69/front.jpg','Windbreak Jacket With Hide Hood','SS22-006',"69","Fabric : 62% Ceather 38% Polyester","Lining : 100% Polyester"],
//   ['images/jackets/women/70/front.jpg','Windbreak Jacket With Hide Hood','SS22-005',"70","Fabric : 100% Nylon","Lining : 100% Polyester"],
//   ['images/jackets/women/71/front.jpg','Bomber Jacket With Hoody','SS22-004',"71","Fabric : 58% Ceather 42% Nylon","Lining : 100% Polyester"],
//   ['images/jackets/women/72/front.jpg','Long Coat With Hoody','SS22-002',"72","Fabric : 100% Polyester","Lining : 100% Polyester"],
//   ['images/jackets/women/73/front.jpg','Oversized Long Coat With Hoody','SS22-001',"73","Fabric : 100% Nylon","Lining : 100% Polyester"],
// ]
//
// const womens3 = [
//   ['images/jackets/women/104/front.jpg','Oversized High Collar Hooded Down Short Jacket ','FW21-032',"104", "Fabric : 100% Polyester ",
// "Lining : 100% Polyester ", "Filling : 80% Down 20% Feather "],
//   ['images/jackets/women/77/front.jpg','Down Short Biker With Fake Fur Collar','FW21-031',"77", "Fabric : 100% Nylon Super Light Weight",
// "Lining : 100% Polyester", "Filling : 80% Down 20% Feather 140G"],
//   ['images/jackets/women/78/front.jpg','Down Jacket','FW21-029',"78","Soft Touch Short Hair Leopard Print High Collar Fake Fur Jacket ",
// "Fabric : 100% Polyester ","Lining : 100% Polyester", "Filling : 100% Polyester Padding 100G "],
//   ['images/jackets/women/79/front.jpg','Soft Touch Short Hair Leopard Print Fake Fur','FW21-028',"79",
// "Fabric : 100% Polyester ", "Lining : 100% Polyester ","Filling : 100% Polyester Padding 100G"],
//   ['images/jackets/women/80/front.jpg','Digital Flower Print Fake Down Hooded Coat','FW21-027',"80",
// "Fabric : 100% Polyester", "Lining : 100% Polyester", "Filling : Recycled Plastic Fake Down 250G"],
//   ['images/jackets/women/81/front.jpg','Fake Down Single Breast Trench Coat ','FW21-026',"81", "Fabric : 14% Ceather 86% Polyester Twill", "Lining : 100% Polyester",
// "Filling : ' 3M ' Fake Down 240G"],
//   ['images/jackets/women/82/front.jpg','Super Light Weight Fake Down Blazer','FW21-025',"82","Fabric : 100% Polyester Super Light Weight ","Lining : 100% Polyester ","Filling : '3M' Fake Down 210G "],
//   ['images/jackets/women/83/front.jpg','Oversized Fake Down Hooded Vest','FW21-024',"83", "Fabric : 100% Nylon","Lining : 100% Nylon","Filling : Recycled Plastic Fake Down 215G"],
//   ['images/jackets/women/84/front.jpg','Puff Collar Fake Down Jacket','FW21-023',"84","Fabric : 100% Polyester","Lining : 100% Polyester","Filling : Recycled Plastic Fake Down 200G "],
//   ['images/jackets/women/85/front.jpg','Digital Leopard Print Fake Down Coat ','FW21-022',"85","Fabric : 100% Polyester ","Lining : 100% Polyester","Filling : '3M' Fake Down 320G"],
//   ['images/jackets/women/86/front.jpg','Digital Leopard Print Fake Down Jacket','FW21-021',"86","Fabric : 100% Polyester","Lining : 100% Polyester ", "Filling : 'DUPONT' Fake Down 210G "],
//     ['images/jackets/women/87/front.jpg','Digital Print Fake Down Jacket','FW21-020',"87","Fabric : 100% Polyester","Lining : 100% Polyester","Filling : '3M' Fake Down 290G"],
// ]
//
// const womens4 = [
//   ['images/jackets/women/88/front.jpg','Knitting Sleeve Down Jacket','FW21-019',"88","Fabric : 100% Nylon High Density","Lining : 100% Polyester","Filling : 80% Down 20% Feather 120G"],
//   ['images/jackets/women/89/front.jpg','Contrast Color Detachable Inner Front PLacket Fake Down Hooded Coat','FW21-017',"89",
// "Fabric : 100% Polyester Ceather Feel Pongee","Lining : 100% Polyester ","Filling : 'DUPONT' Fake Down 320G"],
//   ['images/jackets/women/90/front.jpg','Oversized High Stand Collar Fake Down Short Bomber','FW21-018',"90","Fabric : 100% Polyester","Lining : 100% Polyester","Filling : Recycled Plastic Fake Down 285G"],
//   ['images/jackets/women/91/front.jpg','Contrast Sew Thread Color Down Jacket','FW21-016',"91","Fabric : 100% Polyester","Lining : 100% Polyester","Filling : 80% Down 20% Feather / 115G"],
//   ['images/jackets/women/92/front.jpg','Double Layer Contrast Fabric Down Jacket With Vest ','FW21-015',"92","Fabric : 100% Nylon High Density","Inner Fabric : Ceather 55% Polyester 45% Knitting Bonding Fabric ","Filling : 80% Down 20% Feather / 60G "],
//   ['images/jackets/women/93/front.jpg','High Stand Collar With Hide Hooded Down Jacket','FW21-014',"93","Fabric : 100% Polyester ","Lining : 100% Polyester","Filling : 80% Down 20% Feather / 140G"],
//   ['images/jackets/women/94/front.jpg','Suiting Fabric With Fleece High Collar Fake Down Coat','FW21-013',"94","Fabric : 100% Polyester","Lining : 100% Polyester","Filling : '3M' Fake Down / 320G"],
//   ['images/jackets/women/95/front.jpg','Big Collar Fake Down Coat','FW21-012',"95","Fabric : 100% Polyester","Lining : 100% Polyester","Filling : 'DUPONT' Fake Down / 260G"],
//   ['images/jackets/women/96/front.jpg','Garment Stone Wash 2 Layer Military Down Jacket','FW21-011',"96","Fabric : 100% Ceather ","Lining : 100% Nylon","Filling : 80% Down 20% Feather / 85G"],
//   ['images/jackets/women/97/front.jpg','High Stand Collar Double Breasted Fake Down Jackett','FW21-008',"97","Fabric : 100% Polyester","Lining : 100% Polyester","Filling : '3M' Fake Down / 215G"],
//   ['images/jackets/women/98/front.jpg','Big Collar Hooded Down Coat','FW21-007',"98","Fabric : 43% Nylon 57% Polyester","Lining : 100% Polyester","Filling : 80% Down 20% Feather / 190G"],
//   ['images/jackets/women/99/front.jpg','Over Size Down BOMBER Jacket','FW21-006',"99","Fabric : 100% Nylon","Lining : 100% Nylon","Filling : 80% Down 20% Feather / 130G"],
//   ['images/jackets/women/100/front.jpg','Detachable Contrast Color Inner Front PLacket Trench Coat','FW21-004',"100","Fabric : 68% Ceather 32% Nylon","Lining : 100% Nylon ","Filling : '3M' Fake Down / 240G"],
//   ['images/jackets/women/101/front.jpg','Fake Down Short Trench Coat','FW21-003',"101","Fabric : 100% Polyester Twill","Fabric : 100% Polyester ","Filling : '3M' Fake Down 290G"],
//   ['images/jackets/women/102/front.jpg','Detachable Contrast Color Inner Fake Down Hood Coat','FW21-002',"102","Fabric : 100% Polyester","Lining : 100% Nylon","Filling : 'DUPONT' Fake Down / 250G"],
//   ['images/jackets/women/103/front.jpg','Contract Fabric Down Coat','FW21-001',"103","Fabric : 62% Ceather 38% Nylon","Lining : 100% Nylon ","Filling : 80% Down 20% Feather / 150G"],
// ]
//
// const womens7 = [
//   ['images/jackets/women/22/front.jpg','Down Jacket','FW20-017',"22"],
//   ['images/jackets/women/33/front.jpg','Fake Down Coat','FW20-005',"33"],
//   ['images/jackets/women/15/front.jpg','Trench Coat with Hood','FW20-025',"15"],
//   ['images/jackets/women/16/front.jpg','Trench Coat','FW20-029',"16"],
//   ['images/jackets/women/17/front.jpg','Blazer','FW20-033',"17"],
//   ['images/jackets/women/18/front.jpg','Long Blazer','FW20-011',"18"],
//   ['images/jackets/women/20/front.jpg','Fake Down Jacket','FW20-011',"20"],
//   ['images/jackets/women/31/front.jpg','Fake Down Jacket','FW20-026',"31"],
//   ['images/jackets/women/21/front.jpg','Fake Down Coat','FW20-008',"21"],
//   ['images/jackets/women/23/front.jpg','Down Jacket','FW20-003',"23"],
// ]
//
// const womens8 = [
//   ['images/jackets/women/24/front.jpg','Fake Fur Jacket','FW20-032',"24"],
//   ['images/jackets/women/25/front.jpg','Trench Coat','FW20-027',"25"],
//   ['images/jackets/women/26/front.jpg','Down Jacket','FW20-014-1',"26"],
//   ['images/jackets/women/27/front.jpg','Fake Down Coat','FW20-019',"27"],
//   ['images/jackets/women/29/front.jpg','Trench Coat','FW20-011',"29"],
//   ['images/jackets/women/8/front.jpg','Down Jacket','FW20-011',"8"],
//   ['images/jackets/women/32/front.jpg','Fake Down Jacket','FW20-012',"32"],
//   ['images/jackets/women/9/front.jpg','Down Jacket','FW20-018',"9"],
//   ['images/jackets/women/34/front.jpg','Fake Down Jacket','FW20-031',"34"],
// ]
//
const mens2 = [
  ['images/jackets/men/35/front.jpg','Detachable Inner Fake Down Coat','FW21-M020',"35",
   "Fabric : 100% Polyester With PU Bonding ",
   "Inner : 100% Nylon High Density",
 "Filling :  'DUPONT' Fake Down 200G"],
  ['images/jackets/men/36/front.jpg','Comfort Quilting Jacket','FW21-M018',"36", "Fabric : 100% Polyester Super Light Weight", "Lining : 100% Polyester", "Filling : '3M' Fake Down 260G"],
  ['images/jackets/men/37/front.jpg','Suiting Fabric Fake Down Jacket','FW21-M017',"37","Fabric : 100% Polyester", "Lining : 100% Polyester", "Filling :  '3M'  Fake Down / 225G"],
  ['images/jackets/men/38/front.jpg','Comfort Fake Down Jacket','FW21-M016',"38", "Fabric : 85% Nylon 15% Spandex '4 WAY Spandex'"],
  ['images/jackets/men/39/front.jpg','Double Breast Padding Coat','FW21-M014',"39", "Fabric : 68% Ceather 32% Nylon", "Lining : 100% Nylon", "Filling : ' DUPONT ' Fake Down "],
  ['images/jackets/men/40/front.jpg','Down Jacket With Fake Fur Collar','FW21-M012',"40", "Fabric : 100% Nylon High Density",
"Lining : 100% Polyester", "Filling : 80% Down 20% Feather / 130G"],
  ['images/jackets/men/41/front.jpg','Down Jacket','FW21-M011',"41", "Fabric : 100% Polyester", "Lining : 100% Polyester", "Filling : 80% Down 20% Feather / 160G"],
  ['images/jackets/men/42/front.jpg','Fleece Quilting Jacket','FW21-M010',"42", "Fabric : 100% Polyester", "Lining : 100% Nylon", "Filling : 100% Polyester Padding 80G"],
  ['images/jackets/men/43/front.jpg','Down Jacket','FW21-M009',"43","Fabric : 100% Polyester Super Light Weight ","Lining : 100% Polyester Super Light Weight ","Filling : 90% Down 10% Feather 93G"],
  ['images/jackets/men/44/front.jpg','Down Jacket','FW21-M008',"44","Fabric : 100% Nylon","Lining : 100% Polyester","Filling : 100% Polyester Padding 180G"],
  ['images/jackets/men/45/front.jpg','Down Jacket','FW21-M007',"45","Fabric : 100% Polyester With PU CoatING ", "Lining : 100% Polyester","Filling : 80% Down 20% Feather / 180G"],
  ['images/jackets/men/46/front.jpg','Constract Fabric Fake Down Jacket','FW21-M005',"46","Fabric : 100% Polyester Bonding With Fleece ", "Lining : 100% Nylon High Density", "Filling : ' DUPONT ' Fake Down / 70G"],
  ['images/jackets/men/47/front.jpg','Detachable Inner Fake Down Coat with Seam Tape','FW21-M004',"47",
"Fabric : 100% Polyester With PU Coating", "Inner : 100% Nylon High Density", "Filling : ' DUPONT ' Fake Down "],
  ['images/jackets/men/48/front.jpg','Denim Pocket Fake Down Jacket','FW21-M003',"48", "Fabric : 100% Nylon Super High Density ", "Lining : 100% Nylon Super High Density", "Filling : DUPONT Fake Down / 205G"],
  ['images/jackets/men/49/front.jpg','Garment Stone Wash Down Jacket','FW21-M002',"49", "Fabric : 100% Ceather", "Lining : 100% Nylon", "Filling : 80% Down 20% Feather / 170G"],
]

const mens3 = [
  ['images/jackets/men/1/side.jpg','Down Jacket', 'FW20-M008',"1"],
  ['images/jackets/men/2/front.jpg','Fake Down Jacket', 'FW20-M002',"2"],
  ['images/jackets/men/3/front.jpg','Down Jacket', 'FW20-M014',"3"],
  ['images/jackets/men/4/front.jpg','Padding Jacket', 'FW20-M001',"4"],
  ['images/jackets/men/5/front.jpg','Bonding Jacket','FW20-M021',"5"],
  ['images/jackets/men/6/front.jpg','Down Jacket','FW20-M015',"6"],
  ['images/jackets/men/7/front.jpg','Down Jacket','FW20-M012-1',"7"],
  ['images/jackets/men/10/front.jpg','Trench Coat with Inner Padding Vest','FW20-M010',"10"],
  ['images/jackets/men/11/front.jpg','Down Jacket','FW20-M006',"11"],
  ['images/jackets/men/12/front.jpg','Fake Down Jacket','FW20-M011',"12"],
  ['images/jackets/men/13/side.jpg','Down Jacket','FW20-M015',"13"],
  ['images/jackets/men/14/front.jpg','Padding Jacket','FW20-M016',"14"],
  ['images/jackets/men/19/front.jpg','Recycle Padding Jacket','FW20-M017-1',"19"],
  ['images/jackets/men/28/front.jpg','Down Jacket','FW20-M024',"28"],
];

const mens1 = [
  ['images/jackets/men/105/front.jpg','Windbreak Coat With Water Proof', 'SS22-M018',"105", "Fabric : 100% Polyester Bonding Fabric "],
  ['images/jackets/men/106/front.jpg','Light WEigh Fake Down Jacket', 'SS22-M017',"106","Fabric : 90% Nylon 10% Spandex","Lining : 90% Nylon 10% Spandex","Filling : 'DUPONT' Fake Down"],
  ['images/jackets/men/107/front.jpg','Windbreak Jacket With Water Proof', 'SS22-M016',"107","Fabric : 100% Nylon"],
  ['images/jackets/men/108/front.jpg','Digital Print Windbreak Parka With Water Proof', 'SS22-M015',"108","Fabric : 100% Polyester "],
  ['images/jackets/men/109/front.jpg','Digital Print Windbreak Jacket ', 'SS22-M013',"109","Fabric : 100% Polyester ","Lining : 100% Polyester MESH"],
  ['images/jackets/men/110/front.jpg','Ceather Mix Trench Coat', 'SS22-M012',"110","Fabric : 60% Ceather 40% Nylon","Lining : 100% Polyester "],
  ['images/jackets/men/111/front.jpg','Windbreak Jacket ', 'SS22-M011',"111","Fabric : 100% Polyester Bonding Fabric ","Lining : 100% Polyester "],
  ['images/jackets/men/112/front.jpg','Windbreak Jacket', 'SS22-M010',"112","Fabric : 100% Nylon","Lining : 100% Polyester "],
  ['images/jackets/men/113/front.jpg','Windbreak Jacket With Water Proof', 'SS22-M009',"113","Fabric : 100% Nylon","Lining : 100% Polyester "],
  ['images/jackets/men/114/front.jpg','Windbreak Jacket With Water Proof', 'SS22-M008',"114","Fabric : 100% Polyester Bonding Fabric ","Lining : 100% Polyester "],
  ['images/jackets/men/115/front.jpg','Ceather Mix Parka', 'SS22-M007',"115","Fabric : 38% Ceather 38% Polyester 24% T","Lining : 100% Polyester "],
  ['images/jackets/men/116/front.jpg','Windbreak Jacket With Water Proof', 'SS22-M005',"116","Fabric : 100% Polyester","Lining : 100% Polyester "],
  ['images/jackets/men/117/front.jpg','Windbreak Jacket With Water Proof', 'SS22-M004',"117","Fabric : 84% Nylon 16% Spandex Bonding Fabric ","Lining : 100% Polyester MESH "],
  ['images/jackets/men/118/front.jpg','Reflection Fabric Windbreak Jacket', 'SS22-M003',"118","Fabric : 100% Polyester Bonding Fabric ","Lining : 100% Polyester "],
  ['images/jackets/men/119/front.jpg','Parka', 'SS22-M001',"119","Fabric : 100% Nylon Mini Rib Stop Ceather Touch","Lining : 100% Polyester "]
];

const womens2 = [
  ['images/jackets/women/144/front.jpg','FW23-022', 'FW23-022',"144"],
  ['images/jackets/women/145/front.jpg','FW23-023', 'FW23-023',"145"],
  ['images/jackets/women/146/front.jpg','FW23-024', 'FW23-024',"146"],
  ['images/jackets/women/147/front.jpg','FW23-025', 'FW23-025',"147"],
  ['images/jackets/women/148/front.jpg','FW23-026', 'FW23-026',"148"],
  ['images/jackets/women/149/front.jpg','FW23-027', 'FW23-027',"149"],
  ['images/jackets/women/150/front.jpg','FW23-029', 'FW23-029',"150"],
  ['images/jackets/women/151/front.jpg','FW23-030', 'FW23-030',"151"],
  ['images/jackets/women/152/front.jpg','FW23-031', 'FW23-031',"152"],
  ['images/jackets/women/153/front.jpg','FW23-032', 'FW23-032',"153"],
  ['images/jackets/women/154/front.jpg','FW23-034', 'FW23-034',"154"],
  ['images/jackets/women/155/front.jpg','FW23-035', 'FW23-035',"155"],
  ['images/jackets/women/156/front.jpg','FW23-036', 'FW23-036',"156"],
  ['images/jackets/women/157/front.jpg','FW23-037', 'FW23-037',"157"],
  ['images/jackets/women/158/front.jpg','FW23-038', 'FW23-038',"158"],
  ['images/jackets/women/159/front.jpg','FW23-039', 'FW23-039',"159"],
  ['images/jackets/women/160/front.jpg','FW23-040', 'FW23-040',"160"],
  ['images/jackets/women/161/front.jpg','FW23-041', 'FW23-041',"161"],
  ['images/jackets/women/162/front.jpg','FW23-043', 'FW23-043',"162"],
]

const womens1 = [
  ['images/jackets/women/121/front.jpg','FW23-001', 'FW23-001',"121"],
  ['images/jackets/women/122/front.jpg','FW23-002', 'FW23-002',"122"],
  ['images/jackets/women/123/front.jpg','FW23-003', 'FW23-003',"123"],
  ['images/jackets/women/124/front.jpg','FW23-004', 'FW23-004',"124"],
  ['images/jackets/women/125/front.jpg','FW23-005', 'FW23-005',"125"],
  ['images/jackets/women/126/front.jpg','FW23-006', 'FW23-006',"126"],
  ['images/jackets/women/127/front.jpg','FW23-007', 'FW23-007',"127"],
  ['images/jackets/women/128/front.jpg','FW23-008', 'FW23-008',"128"],
  ['images/jackets/women/130/front.jpg','FW23-010', 'FW23-010',"130"],
  ['images/jackets/women/131/front.jpg','FW23-011', 'FW23-011',"131"],
  ['images/jackets/women/132/front.jpg','FW23-012', 'FW23-012',"132"],
  ['images/jackets/women/133/front.jpg','FW23-013', 'FW23-013',"133"],
  ['images/jackets/women/134/front.jpg','FW23-014', 'FW23-014',"134"],
  ['images/jackets/women/135/front.jpg','FW23-015', 'FW23-015',"135"],
  ['images/jackets/women/136/front.jpg','FW23-016', 'FW23-016',"136"],
  ['images/jackets/women/137/front.jpg','FW23-017', 'FW23-017',"137"],
  ['images/jackets/women/138/front.jpg','FW23-018', 'FW23-018',"138"],
  ['images/jackets/women/139/front.jpg','FW23-019', 'FW23-019',"139"],
  ['images/jackets/women/140/front.jpg','FW23-020A', 'FW23-020A',"140"],
  ['images/jackets/women/141/front.jpg','FW23-020B', 'FW23-020B',"141"],
  ['images/jackets/women/142/front.jpg','FW23-021A', 'FW23-021A',"142"],
  ['images/jackets/women/143/front.jpg','FW23-021B', 'FW23-021B',"143"],
]

// <h2>
//   |
// </h2>
// <h2 className={this.state.page === "3" ? "gender-button active" : "gender-button"}
//   onClick={()=>this.setState({images: this.state.gender === "men" ? mens3 : womens3, page:"3"})}>
//   3
// </h2>
//   {this.state.gender === "women" && (
//     <>
//     <h2>
//       |
//     </h2>
//     <h2 className={this.state.page === "4" ? "gender-button active" : "gender-button"}
//       onClick={()=>this.setState({images:  womens4, page:"4"})}>
//       4
//     </h2>
//     <h2>
//       |
//     </h2>
//     <h2 className={this.state.page === "5" ? "gender-button active" : "gender-button"}
//       onClick={()=>this.setState({images: womens5, page:"5"})}>
//       5
//     </h2>
//     <h2>
//       |
//     </h2>
//     <h2 className={this.state.page === "6" ? "gender-button active" : "gender-button"}
//       onClick={()=>this.setState({images:  womens6, page:"6"})}>
//       6
//     </h2>
//     <h2>
//       |
//     </h2>
//     <h2 className={this.state.page === "7" ? "gender-button active" : "gender-button"}
//       onClick={()=>this.setState({images:  womens7, page:"7"})}>
//       7
//     </h2>
//     <h2>
//       |
//     </h2>
//     <h2 className={this.state.page === "8" ? "gender-button active" : "gender-button"}
//       onClick={()=>this.setState({images:  womens8, page:"8"})}>
//       8
//     </h2>
//     </>
//     )
//   }


// <h2 className={this.state.gender === "men" ? "gender-button active" : "gender-button"}
//   onClick={()=>this.setState({images:mens1, title:"Men's",gender:"men",page:"1"})}>
//   Men
// </h2>
// <h2>
//   |
// </h2>

class SamplesPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      images:womens1,
      title:"Women's",
      gender:"women",
      page:"1"
    }
  }

  render() {
    return (
        <main className="samples-page">
          <div className="samples-title">
            <h1><FormattedMessage id="sample.title"
              defaultMessage="Samples"/></h1>
            <div className="gender-toggle">
              <h2 className={this.state.gender === "men" ? "gender-button" : "gender-button active"}
                 onClick={()=>this.setState({images:womens1, title:"Women's",gender:"women",page:"1"})}>
                Women
              </h2>
            </div>
            <div className="page-toggle">
              <h3>Page</h3>
              <h2 className={this.state.page === "1" ? "gender-button active" : "gender-button"}
                onClick={()=>this.setState({images: this.state.gender === "men" ? mens1 : womens1, page:"1"})}>
                1
              </h2>
              <h2>
                |
              </h2>
              <h2 className={this.state.page === "2" ? "gender-button active" : "gender-button"}
                onClick={()=>this.setState({images: this.state.gender === "men" ? mens2 : womens2, page:"2"})}>
                2
              </h2>
            </div>
          </div>
          <SamplePageCatalog images={this.state.images} title={this.state.title} gender={this.state.gender}/>
        </main>
    );
  }
}

export default SamplesPage;
