import React, { Component } from 'react';
import{
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

import './App.css';

//components
import Header from './components/headerComponent/header';
import Footer from './components/footerComponent/footer';
import Homepage from './components/pages/homePageComponent/homePage';
import SamplePage from './components/pages/samplePageComponent/samplePage';
import SampleItem from './components/pages/samplePageComponent/sampleItemComponent/sampleItem';
import RequestPage from'./components/pages/requestPageComponent/requestPage';
import ScrollToTop from './components/scrollToTopComponent/scrollToTop';
import ProductionPage from './components/pages/productionPageComponent/productionPageStandalone';

//font-awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faTimesCircle,
  faArrowLeft,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

import { IntlProvider } from 'react-intl';
import {addLocaleData} from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_zh from 'react-intl/locale-data/zh';
import messages_zh from './translations/zh.json';
import messages_en from './translations/en.json';

const messages = {
  'zh': messages_zh,
  'en': messages_en
}

const language = (navigator.languages && navigator.languages[0])
               || navigator.language
               || navigator.userLanguage
               || 'en';

addLocaleData([...locale_en, ...locale_zh]);

library.add(faBars);
library.add(faTimesCircle);
library.add(faArrowLeft);
library.add(faArrowRight);

class App extends Component {
  constructor(props){
      super(props);
      this.state = {
        locale: language
      }
      this.setLocale = this.setLocale.bind(this);
  }

  setLocale(loc){
    this.setState({
      locale: loc
    })
  }

  render() {
    return (
        <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>

          <Router>
            <ScrollToTop>
              <div className="App">
                <Header setLocale = {this.setLocale} selected={this.state.locale} />
                <Route exact path='/Dun_Huang' component={Homepage}></Route>
                <Route exact path='/' component={Homepage}></Route>
                <Route exact path='/Samples' component={SamplePage}></Route>
                <Route exact path='/Production' component={ProductionPage}></Route>
                <Route exact path='/Contact' component={RequestPage}></Route>
                <Route path='/SampleItem/:gender/:id/:serial' component={SampleItem}></Route>
                <Footer setLocale = {this.setLocale} selected={this.state.locale}/>
              </div>
            </ScrollToTop>
          </Router>

        </IntlProvider>
    );
  }
}

export default App;
