import React, { Component } from 'react';
import './header.css';
import NavMenu from './navMenuComponent/navMenu2';
// import NavMenuToggle from './navMenuToggleComponent/navMenuToggle';
//         <NavMenuToggle click={this.toggleNavMenu} open={open}/>

class Header extends Component {

  constructor(props){
    super(props);
    this.state = {
      navMenuOpen: false
    }
  }

  toggleNavMenu = () => {
    this.setState((prevState) => {
      return {navMenuOpen: !prevState.navMenuOpen};
    });
  };

  render() {
    let open;
    if (this.state.navMenuOpen){
      open = true;
    }else{
      open = false;
    }

    return (
      <header className = "header" >
        <NavMenu />
      </header>
    );

  }
}

export default Header;
