import React, { Component } from 'react';
import './aboutSection.css';
import Carousel from '../../../carouselComponent/carousel';
import {
  NavLink
} from 'react-router-dom';

import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

const images = [
  'images/jackets/women/33/front.jpg',
  'images/jackets/men/6/front.jpg',
  'images/jackets/women/17/side.jpg',
  'images/jackets/women/31/front.jpg',
  'images/jackets/men/5/front.jpg',
  'images/jackets/women/20/front.jpg',
];

class AboutSection extends Component {
  render() {
    return (
        <div className="about-section">


          <div className="about-section-content">
            <h1 className='about-section-subheader'>
              <FormattedMessage id="home.services.title"
                defaultMessage="Our Services"/>
            </h1>
          </div>
          <NavLink className="sample-link" to="/Samples">
              <button className="sample-link-text">More Samples</button>
          </NavLink>

          <p><FormattedHTMLMessage id="home.services.p1" defaultMessage="Affordable, reliable, <b>sampling</b> and <b>bulk production</b>"/></p>



          <div className='about-section-desc-wrapper'>
            <div className='about-section-description'>

              <div className='carousel-wrapper'>
                <Carousel images={images}/>
              </div>

            </div>
          </div>

          <p className='about-section-stuff'>
            <FormattedHTMLMessage id="home.services.p2"
              defaultMessage="We specialize in <b>coats</b>, <b>jackets</b> and <b>padded outerwear</b>"/>
          </p>



        </div>
    );
  }
}

export default AboutSection;
