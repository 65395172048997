import React, { Component } from 'react';
import './infoSection.css';
import MapContainer from './mapComponent/map';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  NavLink
} from 'react-router-dom';

class InfoSection extends Component {
  render() {
    let className;
    if (this.props.border){
      className='info-section border';
    }else{
      className='info-section no-border';
    }
    return (
        <div id="info" className={className}>
          <div className='header-div'>
            <h1 className='info-header'>
              <FormattedMessage id="production.title" defaultMessage="Production"/>
            </h1>
          </div>
          <br/>
          <div className="factory">
            <img alt='factory1' class="factory-img" src="https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory/factory2.jpg"></img>
          </div>
          <NavLink className="production-link" to="/Production">
              <button className="production-link-text">More Photos</button>
          </NavLink>
          <div className='info-content-wrapper'>
            <div className='info-content'>
              <div className='info-map'>
                <FormattedHTMLMessage id="production.location"
                  defaultMessage="<p>Our production facility is located in</p>
                  <p><b>Henan, China</b></p><br/>"/>
                <MapContainer/>
              </div>

              <div className='info-words'>
                <p><FormattedHTMLMessage id="production.p1"
                  defaultMessage="Established since <b>1995</b></p><br/>"/></p>
                <p><FormattedHTMLMessage id="production.p2"
                  defaultMessage="<b>260+</b> fully-functional machines, "/></p>
                <p><FormattedHTMLMessage id="production.p3"
                  defaultMessage="<b>350+</b> dedicated workers, "/></p>
                <p>
                  <FormattedHTMLMessage id="production.p4"
                    defaultMessage="Producing up to <b>35,000</b> pieces a month"/></p>
                <p>
                <p>
                  <FormattedHTMLMessage id="production.p6"
                      defaultMessage="Vast connections within the area for <b>materials sourcing</b>"/>
                  </p><br/>
                  <FormattedHTMLMessage id="production.p5"
                    defaultMessage="We've exported to <b>Europe</b>, <b>China</b> and <b>Hong Kong</b>"/>
                  </p>
              </div>
            </div>
          </div>


        </div>
    );
  }
}

export default InfoSection;
