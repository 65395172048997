import React, { Component } from 'react';
// import ProductSlideShow from './productSlideShowComponent/productSlideShow';
import './productSection.css';
import {FormattedMessage} from 'react-intl';


class ProductSection extends Component {
  render() {
    return (
        <div className="homepage-product">

            <div className='homepage-product-content'>
              <h2><FormattedMessage id="home.product.title"
                defaultMessage="High-Quality Garment Manufacturing"/></h2>
              <h3 className='date'>
                <FormattedMessage id="home.product.subtitle"
                  defaultMessage="Since 1995"/>
              </h3>
            </div>

            <div className='slideshow'>
              <div className='slideshow-img'></div>

              <div className='slide-show-description'>

                <div>
                  <p><FormattedMessage id="home.product.p1"
                    defaultMessage="We've worked with..."/></p>
                  <br/>
                  <ul>
                    <li>Caroll</li>
                    <li>G2000</li>
                    <li>Marc O'Polo</li>
                    <li>Claudie Pierlot</li>
                    <li>Paolo Pecora</li>
                    <li>LIEBESKIND</li>
                    <li>Chevignon</li>
                    <li>ccaabb</li>
                    <br/>
                      <p><FormattedMessage id="home.product.p2"
                        defaultMessage="...and more"/></p>
                  </ul>
                </div>

              </div>

            </div>

        </div>
    );
  }
}

export default ProductSection;
