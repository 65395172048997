import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import './slide.css';

class Slide extends Component {

  render() {
    let className;
    if (this.props.active){
      className= 'slide active-card';
    }else{
      className = 'slide';
    }
    return (
        <img onClick={this.props.onClick} id={`card-${this.props.id}`} alt='slide' className={className} src={this.props.image}>
        </img>
    );
  }
}

export default Slide;
