import React, { Component } from 'react';
import RequestForm from './requestFormComponent/requestForm';
import './requestMain.css';
import { FormattedMessage } from 'react-intl';

class RequestMain extends Component {
  render() {
    return (
        <div className="request-main">
          <div className='request-page-content'>
            <h1 className='request-page-header'>
              <FormattedMessage id="contact.title"
                defaultMessage="Contact Us"/>
            </h1>
            <br/>
            <RequestForm/>
          </div>
        </div>
    );
  }
}

export default RequestMain;
