import React, { Component } from 'react';
import Bounce from 'react-reveal';
import './requestForm.css';
import { FormattedMessage } from 'react-intl';

class ContactForm extends Component {
  render() {
    return (
      <Bounce right>
        <div className='request-form-content'>
          <div className='request-form-header'>
            <p>
              <FormattedMessage id="contact.company"
                defaultMessage="Dun Huang Hong Kong Garment Co., Ltd."/>
            </p><br/>
            <p>
              <i class="fas fa-id-badge"></i>
              <FormattedMessage id="contact.contact"
                defaultMessage=" Contact Mr. Yung Tung Ying"/>
            </p>
            <p><i class="fas fa-envelope"></i> tyyung@dunhuang.com.hk</p>
            <p>
              <i class="fas fa-phone"></i>
              <FormattedMessage id="contact.office"
                defaultMessage=" HK Office: "/>
               (852) 2793 0073
           </p>
          </div>
        </div>
      </Bounce>
    );
  }
}

export default ContactForm;
