import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './navMenu2.css';

class NavMenu extends Component {
  render() {
    return (
        <div className="App-header2">
          <div className='logo-wrapper'>
            <p className="main-title">Dun Huang</p>
          </div>

          <div className='nav-wrapper2'>
            <ul className='nav2'>
              <NavLink exact={true} activeClassName='is-active2' to="/">
                <li>Home</li>
              </NavLink>
              <NavLink activeClassName='is-active2' to="/Samples">
                <li>Samples</li>
              </NavLink>
              <NavLink exact={true} activeClassName='is-active2' to="/Production">
                <li>Production</li>
              </NavLink>
              <NavLink exact={true} activeClassName='is-active2' to="/Contact">
                <li>Contact</li>
              </NavLink>
            </ul>
          </div>
        </div>
    );
  }
}

export default NavMenu;
