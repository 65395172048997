import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import TitleSection from './titleSectionComponent/titleSection2';
import ProductSection from './productSectionComponent/productSection';
import AboutSection from './aboutSectionComponent/aboutSection';
import InfoSection from '../../infoSectionComponent/infoSection';
import RequestSection from './requestSectionComponent/requestSection';
import './homePage.css';

class Homepage extends Component {
  render() {
    return (
        <main className="homePage">
          <TitleSection />
          <ProductSection/>
          <AboutSection />
          <InfoSection border={true}/>
          <RequestSection/>
        </main>
    );
  }
}

export default Homepage;
