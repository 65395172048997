import React, { Component } from 'react';
import Slide from './slideComponent/slide';
// import {Link} from 'react-router-dom';
import './carousel.css';

class Carousel extends Component {
  constructor(props){
    super(props);
    this.state={
      images:this.props.images,
      index: 1
    }
  }

  nextSlide = () => {
    if (this.state.index === this.state.images.length-1){
      return (this.setState({index:0}));
    }
    this.setState({
      index: this.state.index + 1
    })
  }

  prevSlide = () =>{
    if (this.state.index === 0){
      return (this.setState({index:this.state.images.length-1}));
    }
    this.setState({
      index: this.state.index - 1
    })
  }

  goToSlide = (i) =>{
    this.setState({
      index: i
    })
  }

  render() {
    return (
        <div className="carousel">
          <button onClick={()=> this.prevSlide()}><i className="fas fa-chevron-circle-left"></i></button>
          <div className='card-slider' >
            <div className='card-slider-wrapper' style={
                {'transform':`translateX(-${this.state.index*(20)}vw)`}
              }>
              {
                this.state.images.map((image,i) => {
                  let img = this.state.images[i];
                  let active = false;
                  if (this.state.index === i){
                    active=true;
                  }
                  return(
                    <Slide onClick={() => this.goToSlide(i)} key={i} image={img} active={active}/>
                  )
                })
              }
            </div>
          </div>
          <button onClick={()=> this.nextSlide()}><i className="fas fa-chevron-circle-right"></i></button>
        </div>
    );
  }
}

export default Carousel;
