import React, { Component } from 'react';
import './samplingSection.css';

class SamplingSection extends Component {
  render() {
    return (
        <div className="sampling-section">
          <h1 className='sampling-header'>Sampling</h1>
        </div>
    );
  }
}

export default SamplingSection;
