import React, { Component } from 'react';
import InfoSection from '../../infoSectionComponent/infoSectionStandalone';
import SamplingSection from './samplingSectionComponent/samplingSection';

class ProductionPage extends Component {
  render() {
    return (
        <main className="App-header">
          <InfoSection border={false}/>
        </main>
    );
  }
}

export default ProductionPage;
