import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './requestSection.css';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class RequestSection extends Component {
  render() {
    return (
        <div className="request-section">
          <p>
            <FormattedHTMLMessage id="home.requests.p1"
              defaultMessage="We take other <b>requests</b> too"/>
          </p>

          <div className='request-button-wrapper'>
            <Link to='/Contact'>
              <h3 className='request-header'>
                <FormattedMessage id="home.requests.button"
                  defaultMessage="Contact Us"/>
              </h3>
            </Link>
          </div>
          <br/>
          <p>
            <FormattedHTMLMessage id="home.requests.p1"
              defaultMessage="<b>Get in touch!</b>"/>
          </p>

        </div>
    );
  }
}

export default RequestSection;
