import React, { Component } from 'react';
import './footer.css';
import { FormattedMessage } from 'react-intl';

class Footer extends Component {
  render() {
    let engLang, zhLang;
    if (this.props.selected === "zh"){
      engLang =
      (<p className="lang" onClick={()=>this.props.setLocale("en")}>English</p>);
      zhLang =
      (<p className="lang active-lang" onClick={()=>this.props.setLocale("zh")}>中文</p>);
    }else{
      engLang =
      (<p className="lang active-lang" onClick={()=>this.props.setLocale("en")}>English</p>);
      zhLang =
      (<p className="lang" onClick={()=>this.props.setLocale("zh")}>中文</p>);
    }

    // <div className="footer-lang">
    //   <p><FormattedMessage id="footer.lang" defaultMessage="Language:"/></p>
    //   {engLang}
    //   {zhLang}
    // </div>

    return (
      <footer className = "footer">
        <div className = "address-wrapper">
          <div className = "address">
            <b>
              <FormattedMessage id="footer.hkOffice"
                defaultMessage="Hong Kong Office:"/>
            </b>
            <br/><br/>
            香港敦煌服裝有限公司<br/>

            Dun Huang Hong Kong Garment Co., Ltd<br/>
            Unit 807, 8/F., Tai Tak Industrial Building<br/>
            2-12 Kwai Fat Road, <br/>
            Kwai Chung <br/>
            Hong Kong<br/><br/>
            Tel: (852) 2793 0073 <br/>
            Fax: (852) 2793 5187 <br/>
          </div>

          <div className = "address">
            <b>
              <FormattedMessage id="footer.chinaOffice"
              defaultMessage="China Office/Factory:"/>
            </b>
            <br/><br/>
            河南工廠<br/>
            濮陽市鴻鋒服裝制品有限公司<br/>
          <i className="fas fa-map-marker-alt"></i>
            河南省濮陽市范縣金堤路與榆林路交义口東50米路北<br/><br/>
            Henan Factory:<br/>
            Puyang Hongfeng Clothing Products Co. Ltd<br/>
          <i className="fas fa-map-marker-alt"></i>
            Industrial Park, New District Industrial Cluster,<br/>
            Fanxian County, Puyang City, Henan Province<br/>
            China 457500
            <br/><br/>
            Tel./電話: 0393-8752269<br/>
          </div>
        </div>



      </footer>
    );
  }
}

export default Footer;
