import React, { Component } from 'react';
import './samplePageCatalog.css';
import {NavLink} from 'react-router-dom';
import OnImagesLoaded from 'react-on-images-loaded';

class SamplePageCatalog extends Component {
  constructor() {
    super()
    this.state = {
      showImages: false
    }
  }

  render() {
    const gender = this.props.gender;
    return (
          <div className='about-section-content'>
              <div className="catalog">
                <h1 className="gender-division">{this.props.title} Clothing</h1>
                {
                  this.props.images.map((array,i) => {
                    let img = this.props.images[i][0];
                    let title = this.props.images[i][1];
                    let serial = this.props.images[i][2];
                    let id = this.props.images[i][3];
                    let info = this.props.images[i][4];
                    let info2 = this.props.images[i][5];
                    let info3 = this.props.images[i][6];
                    let info4 = this.props.images[i][7];
                    return(
                      <NavLink to={{pathname:`/SampleItem/${gender}/${id}/${serial}`,
                        aboutProps:{
                          title,
                          info,
                          info2,
                          info3,
                          info4
                        }
                      }}>
                          <div className="catalog-img-wrap" style={{ opacity: this.state.showImages ? 1 : 0 }}>
                            <OnImagesLoaded
                              onLoaded={() => this.setState({ showImages: true })}
                              onTimeout={() => this.setState({ showImages: true })}
                              timeout={9000}
                            >
                              <img className="catalog-img" src={img} key={img} alt="jacket" />
                          </OnImagesLoaded>
                              <p className="catalog-img-title"><b>{title}</b></p>
                              <p className="catalog-img-serial">{serial}</p>
                        </div>

                      </NavLink>
                    )
                  })
                }
              </div>
          </div>
    );
  }
}

export default SamplePageCatalog;
