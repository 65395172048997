import React, { Component } from 'react';
import './infoSection.css';
import MapContainer from './mapComponent/map';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import Carousel from 'nuka-carousel';

class InfoSection extends Component {
  render() {
    return (
        <div id="info" className="info-section standalone no-border">
          <div className="factory3">
            <Carousel width='60%' renderCenterLeftControls={({ previousSlide }) => (
              <button className='carousel-button' onClick={previousSlide}>
                <i className="fa fa-arrow-left" />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className='carousel-button' onClick={nextSlide}>
                <i className="fa fa-arrow-right"/>
              </button>
            )}>
            <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/closeup2/4.jpeg'/>
            <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/closeup2/5.jpeg'/>
            <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/closeup2/3.jpeg'/>
            <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/closeup2/1.jpeg'/>
            <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/closeup2/2.jpeg'/>
            </Carousel>
            <div className="standalone-desc">
              <h1 className="standalone-desc-title">Production</h1>
              <div className="standalone-map">
                <div>
                  <p><FormattedHTMLMessage id="production.p1"
                    defaultMessage="Established since <b>1995</b></p>"/></p>
                  <FormattedHTMLMessage id="production.location"
                    defaultMessage="<p>Our production facility is located in</p>
                    <p><b>Henan, China</b></p><br/>"/>
                  <MapContainer/>
                </div>
              </div>
              <br/>
              <div className="standalone-words">
                <p>High quality <b>materials sourcing</b> and..</p><br/>
                <p><FormattedHTMLMessage id="production.p2"
                  defaultMessage="<b>260+</b> top-end machines, "/></p>
                <p><FormattedHTMLMessage id="production.p3"
                  defaultMessage="<b>350+</b> dedicated workers, "/></p>
                <p>
                  <FormattedHTMLMessage id="production.p4"
                    defaultMessage="~<b>35,000</b> pieces produced per month"/></p>
                  <a href="#photos"><i className="fas fa-arrow-down arrow"></i></a>
                </div>

            </div>
          </div>
            <a id="photos"></a>

          <br/><br/><br/>

          <div className="factory2">
            <Carousel width='85%' renderCenterLeftControls={({ previousSlide }) => (
              <button className='carousel-button left' onClick={previousSlide}>
                <i className="fa fa-arrow-left" />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className='carousel-button right' onClick={nextSlide}>
                <i className="fa fa-arrow-right"/>
              </button>
            )}>
              <img alt='factory7' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/11.jpg'/>
              <img alt='factory7' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/12.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/1.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/2.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/5.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/6.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/7.jpg'/>
                <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/3.jpg'/>
                <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/4.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/8.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/9.jpg'/>
              <img alt='factory8' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory2/10.jpg'/>
              <img alt='factory5' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory/OZ1A9919.jpg'/>
              <img alt='factory6' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory/OZ1A9937.jpg'/>
              <img alt='factory7' src='https://dunhuangjackets.s3-ap-southeast-1.amazonaws.com/factory/OZ1A9941.jpg'/>
            </Carousel>
          </div>

        </div>
    );
  }
}

export default InfoSection;
